<template>
  <page-header :breadcrumbs="pathMatch">
    <!-- 油站详情 -->
    <ele-form
      style="margin-top: 40px;"
      v-model="formData"
      :form-desc="formDesc"
      labelPosition="right"
      ref="form"
      :span="14"
      :isShowSubmitBtn="false"
      :isShowBackBtn="false"
      :isShowErrorNotify="false"
      disabled
      :form-attrs="{
        size: 'large'
      }"
    >
    </ele-form>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import EleForm from 'vue-ele-form';
import { QueryGases } from '@/api/gas_info_mgr';
import { getStorage } from '@/utils/storage';

export default {
  components: {
    PageHeader,
    EleForm
  },
  data() {
    return {
      site_id: getStorage('site_id'),
      formDesc: {
        name: {
          type: 'input',
          label: '加油站名称：',
          layout: 24
        },
        credit_code: {
          type: 'input',
          label: '社会信用代码：',
          layout: 24
        },
        legal: {
          type: 'input',
          label: '法人代表：',
          layout: 24
        },
        telephone: {
          type: 'input',
          label: '联系电话：',
          layout: 24
        },
        user_name: {
          type: 'input',
          label: '登录账号：',
          layout: 24
        },
        tank_num: {
          type: 'input',
          label: '油罐数量：'
        },
        machine_num: {
          type: 'input',
          label: '油机数量：'
        },
        address: {
          type: 'input',
          label: '经营地址：'
        },
        start_date: {
          type: 'input',
          label: '经营日期：'
        }
        // image_url: {
        //   label: '证件照：',
        //   type: 'image',
        //   title: '是否必填',
        //   style: {
        //     // 可以在此调整大小
        //     width: '150px',
        //     height: '150px'
        //   },
        //   attrs: {
        //     isShowPreview: true // 默认值开启图片预览功能，可以设置为false，关闭
        //   }
        // }
      },
      formData: {}
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;

    this.handleGetRow();
  },
  methods: {
    handleGetRow() {
      QueryGases({ site_id: this.site_id }).then(res => {
        let { code, data } = res;
        if (code === 0 && data) {
          this.formData = data[0];
          this.formData.user_name = this.formData.credit_code;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
